import { createContext, useContext, useEffect, useState, useCallback } from 'react';
import * as FirebaseAuth from 'firebase/auth';
import * as Sentry from '@sentry/react';
import { FirebaseError } from 'firebase/app';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CheckSessionDocument, DeleteSessionDocument } from '@flashpack/graphql';

type AuthenticationContextType = {
  authenticated: boolean;
  loadingAuthentication: boolean;
};

export const AuthenticationContext = createContext<AuthenticationContextType>({
  loadingAuthentication: false,
  authenticated: false,
});

export const useAuthentication = () => {
  return useContext(AuthenticationContext);
};

export const firebaseErrorCodes = {
  EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
};

export const useFirebaseAuth = () => {
  // the currentUser is obtained asynchronously, which means if the currentUser is null, that might not be true because it might still be loading,
  // this property can be used to react accordingly, e.g block rendering in case knowing the actual state of the user is a must
  const [loadingAuthentication, setLoadingAuthentication] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [checkSession] = useLazyQuery(CheckSessionDocument);
  const [deleteSession] = useMutation(DeleteSessionDocument);

  const auth = FirebaseAuth.getAuth();

  useEffect(() => {
    Sentry.setTag('authenticated', authenticated);
  }, [authenticated]);

  useEffect(() => {
    const unsubscribe = FirebaseAuth.onAuthStateChanged(auth, () => {
      if (auth.currentUser) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setLoadingAuthentication(false);
    });

    return () => unsubscribe();
  }, [auth, setAuthenticated]);

  const signOut = async () => {
    setAuthenticated(false);
    await deleteSession(); // first delete the session then sign out, otherwise checkSession will re-log the user in
    await FirebaseAuth.signOut(auth);
  };

  const refreshToken = async () => {
    if (auth.currentUser) {
      await auth.currentUser.getIdToken(true);
    }
  };

  const signInWithEmailAndPassword = async (
    values: {
      email: string;
      password: string;
    },
    callbacks?: {
      onSuccess?: (data: FirebaseAuth.UserCredential) => void;
      onError?: (err: FirebaseError) => void;
    },
  ) => {
    try {
      const response = await FirebaseAuth.signInWithEmailAndPassword(
        auth,
        values.email,
        values.password,
      );
      callbacks?.onSuccess?.(response);
    } catch (error) {
      callbacks?.onError?.(error as FirebaseError);
    }
  };

  const createUserWithEmailAndPassword = async (
    values: {
      email: string;
      password: string;
    },
    callbacks: {
      onSuccess?: (data: FirebaseAuth.UserCredential) => void;
      onError?: (err: FirebaseError) => void;
    },
  ) => {
    try {
      const response = await FirebaseAuth.createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password,
      );
      callbacks.onSuccess?.(response);
    } catch (error) {
      callbacks.onError?.(error as FirebaseError);
    }
  };

  const signInWithCustomToken = useCallback(
    async (token: string) => {
      const response = await FirebaseAuth.signInWithCustomToken(auth, token);
      return response;
    },
    [auth],
  );

  const loginFromSessionIfPresent = useCallback(async () => {
    const { data } = await checkSession();
    if (data?.checkSession?.customToken) {
      await signInWithCustomToken(data.checkSession.customToken);
    }
  }, [checkSession, signInWithCustomToken]);

  const verifyPasswordResetCode = async (code: string) => {
    return await FirebaseAuth.verifyPasswordResetCode(auth, code);
  };

  const confirmPasswordReset = async (code: string, newPassword: string) => {
    return await FirebaseAuth.confirmPasswordReset(auth, code, newPassword);
  };

  return {
    auth,
    signOut,
    loadingAuthentication,
    loginFromSessionIfPresent,
    signInWithEmailAndPassword,
    refreshToken,
    createUserWithEmailAndPassword,
    verifyPasswordResetCode,
    confirmPasswordReset,
    authenticated,
    signInWithCustomToken,
  };
};
