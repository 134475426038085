import { Field, Form, FormSpy } from 'react-final-form';
import {
  LoadingButton,
  CheckboxFormField,
  Divider,
  FormDropdown,
  FormControlLabel,
  FormTextInput,
  FormDateInput,
  FormPhoneInput,
  Typography,
  createPrioritizedCountryOptions,
  useTheme,
  composeValidators,
  GenericError,
  getCallingCodeForCountry,
} from 'design-system';
import getCallingCode from 'libphonenumber-js';
import { HowDidYouHearAboutUs } from './HowDidYouHearAboutUs';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CheckCustomerEmailValidityDocument,
  CreateCustomerDocument,
  CurrentCustomerDetailsDocument,
} from '@flashpack/graphql';
import { Box, Stack } from '@mui/system';
import { format } from 'date-fns';
import { ImageSplash } from '../shared/ImageSplash/ImageSplash';
import { GenderDropdown } from '../shared/GenderDropdown/GenderDropdown';
import React, { useEffect, useMemo, useState } from 'react';
import { Validator } from 'design-system';
import { useFirebaseAuth } from '@src/auth/useAuthentication';
import { useCustomerAuthorization } from '@src/auth/AuthorizationProvider';
import Layout from '@src/shared/Layout/Layout';
import StepperPill from '../shared/stepper-pill/StepperPill';
import BookDepartureHeader from '../shared/book-departure-header/BookDepartureHeader';
import { Environment, environmentIs } from '@src/environment';
import { useRouting } from '@src/shared/hooks/useRouting';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { useOrderDetails } from '@src/shared/hooks/useOrderDetails';
import { OrderDetails } from '@src/shared/order-details/OrderDetails';
import { Navigate, useLocation, Link } from 'react-router-dom';
import { PageFormTitle } from '@src/shared/page-form-title/PageFormTitle';
import { ResponsiveSideBySide } from '@src/shared/responsive-side-by-side/ResponsiveSideBySide';
import { PageFormLoader } from '@src/shared/page-form-loader/PageFormLoader';
import { trackCheckoutEvent } from '@src/gtm/tracking';

interface CreateAccountFormData {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  nationality: string;
  phoneNumber: string;
  phoneCountryCode: string;
  contactPhone: boolean;
  contactSms: boolean;
  contactEmail: boolean;
  referrer: string;
  referrerOther: string | null;
}

type LocationState = null | {
  redirectBack?: string;
};

const formatValues = (values: CreateAccountFormData) => {
  const { confirmPassword, ...strippedValues } = values;

  const callingCode = getCallingCode(strippedValues.phoneNumber);
  if (!callingCode) {
    throw new Error('Invalid phone number calling code');
  }

  const date = new Date(strippedValues.dateOfBirth);

  const formattedValues = {
    ...strippedValues,
    dateOfBirth: format(date, 'yyyy-MM-dd HH:mm:ss'),
    phoneCountryCode: callingCode.countryCallingCode,
  };

  return formattedValues;
};

export const CreateAccountPage: React.FC = () => {
  const theme = useTheme();
  const { queryParams } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();
  const isPartOfBookingFlow = Boolean(
    queryParams.currencyCode && queryParams.departureCode,
  );

  const location = useLocation();

  const [createCustomer, { error: createCustomerError }] =
    useMutation(CreateCustomerDocument);

  const { currentUser, loadingAuthorization } = useCustomerAuthorization();
  const userLoggedIn = Boolean(currentUser);

  const {
    data: currentCustomerDetailsData,
    loading: loadingCurrentCustomerDetails,
    refetch: refetchCurrentCustomerDetails,
  } = useQuery(CurrentCustomerDetailsDocument, {
    skip: !currentUser,
  });

  const initialFormValues = useMemo(
    () =>
      getInitialValues(currentUser?.email ? { email: currentUser?.email } : undefined),
    [currentUser],
  );

  const [checkEmailExists] = useLazyQuery(CheckCustomerEmailValidityDocument);

  const validateEmail = async (value: string) => {
    if (value && Validator.validEmail(value)) {
      return Validator.validEmail(value);
    }
    if (Validator.required(value)) {
      return Validator.required(value);
    }

    const { data, error } = await checkEmailExists({
      variables: {
        input: {
          email: value,
        },
      },
    });
    if (error) {
      return undefined;
    }
    if (!data?.checkCustomerEmailValidity.valid) {
      return (
        <>
          We can&apos;t create an account with this email address. If you already have an
          account, please{' '}
          <Link
            to={CheckoutRoutePath.LOGIN.value + window.location.search}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: 'inherit',
            }}
            state={{
              redirectBack: (location?.state as LocationState)?.redirectBack,
            }}
          >
            sign in
          </Link>
          .
        </>
      );
    }
  };

  const { data, loading } = useOrderDetails();

  const [error, setError] = useState<string | null>(null);

  const { signInWithEmailAndPassword } = useFirebaseAuth();

  const [defaultCallingCode, setDefaultCallingCode] = useState('+1');

  const prioritizedCountryOptions = useMemo(
    () =>
      createPrioritizedCountryOptions(['us', 'gb']).map((option) => ({
        label: option.label,
        value: option.value.toUpperCase(),
      })),
    [],
  );

  useEffect(() => {
    if (
      !loading &&
      isPartOfBookingFlow &&
      data?.orderDetails &&
      queryParams.currencyCode
    ) {
      trackCheckoutEvent(data?.orderDetails, queryParams.currencyCode);
    }
  }, [loading, isPartOfBookingFlow, data?.orderDetails, queryParams.currencyCode]);

  if (loadingAuthorization || loadingCurrentCustomerDetails) {
    return (
      <Layout HeroComponent={<BookDepartureHeader />}>
        <PageFormLoader />
      </Layout>
    );
  }

  if (currentUser && currentCustomerDetailsData?.currentCustomerDetails) {
    const defaultRedirectBack = isPartOfBookingFlow
      ? CheckoutRoutePath.BOOK_DEPARTURE.value + location.search
      : CheckoutRoutePath.MY_ACCOUNT_DETAILS.value;

    const redirectBack =
      (location?.state as LocationState)?.redirectBack ?? defaultRedirectBack;

    return <Navigate to={redirectBack} />;
  }

  if (createCustomerError && !error) {
    if (createCustomerError.networkError) {
      setError(
        'A temporary error occurred while creating your account. Please try again later.',
      );
    }
    setError(createCustomerError.message);
  }

  const signUp = async (values: CreateAccountFormData) => {
    const formattedValues = formatValues(values);

    await createCustomer({
      variables: {
        input: {
          ...formattedValues,
        },
      },
    });

    await signInWithEmailAndPassword(
      { email: values.email, password: values.password },
      {
        onError: (e) => {
          setError(e.message);
        },
      },
    );

    await refetchCurrentCustomerDetails();
  };

  return (
    <Layout
      HeroComponent={
        isPartOfBookingFlow && (
          <BookDepartureHeader location={data?.orderDetails.location} />
        )
      }
    >
      <ResponsiveSideBySide
        mainSection={
          <Stack gap={2}>
            {isPartOfBookingFlow && <StepperPill currentStep={1} totalSteps={2} />}

            {!userLoggedIn && (
              <Stack gap={2}>
                <PageFormTitle>Create your Flash Pack account</PageFormTitle>
                <Box
                  sx={{
                    padding: 1,
                    backgroundColor: theme.palette.brand.sky,
                    borderRadius: 0.5,
                    width: 'fit-content',
                  }}
                >
                  <Typography variant="Body S">
                    Already have an account?{' '}
                    <Link
                      data-testid="login-link"
                      to={CheckoutRoutePath.LOGIN.value + window.location.search}
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: 'inherit',
                      }}
                      state={{
                        redirectBack: (location?.state as LocationState)?.redirectBack,
                      }}
                    >
                      Sign in
                    </Link>
                  </Typography>
                </Box>
              </Stack>
            )}

            <Form<CreateAccountFormData>
              initialValues={initialFormValues}
              onSubmit={(values) => {
                setError(null);
                return signUp(values);
              }}
              render={({ handleSubmit, values, submitting }) => {
                return (
                  <form
                    autoComplete="on"
                    id="user-registration-form"
                    onSubmit={(e) => {
                      void handleSubmit();
                      e?.preventDefault();
                    }}
                  >
                    <Typography variant="H5">Personal Information</Typography>
                    <Box mb={2} />
                    <Stack gap={2.5} marginBottom={4}>
                      <FormTextInput
                        name="firstName"
                        validate={Validator.required}
                        validateFields={[]}
                        textInputProps={{
                          id: 'user-first-name',
                          autoComplete: 'given-name',
                          placeholder: 'Enter first name...',
                          label: 'First Name (as on passport)',
                          testid: 'create-account-first-name',
                          size: 'large',
                        }}
                      />
                      <FormTextInput
                        name="middleName"
                        validateFields={[]}
                        textInputProps={{
                          id: 'user-additional-name',
                          autoComplete: 'additional-name',
                          placeholder: 'Enter middle name...',
                          label: 'Middle Name (as on passport)',
                          testid: 'create-account-middle-name',
                          size: 'large',
                        }}
                      />
                      <FormTextInput
                        name="lastName"
                        validateFields={[]}
                        textInputProps={{
                          id: 'user-family-name',
                          autoComplete: 'family-name',
                          placeholder: 'Enter last name...',
                          label: 'Last Name (as on passport)',
                          testid: 'create-account-last-name',
                          size: 'large',
                        }}
                        validate={Validator.required}
                      />

                      <GenderDropdown validateFields={[]} />
                      <FormDateInput
                        name="dateOfBirth"
                        validateFields={[]}
                        label="Date of Birth (as on passport)"
                        testid="create-account-date-of-birth"
                        inputProps={{
                          id: 'user-date-of-birth',
                        }}
                        dayInputProps={{
                          id: 'user-date-of-birth-day',
                          name: 'bday-day',
                          autoComplete: 'bday-day',
                        }}
                        monthInputProps={{
                          id: 'user-date-of-birth-month',
                          name: 'bday-month',
                          autoComplete: 'nope', // 'bday-month',
                        }}
                        yearInputProps={{
                          id: 'user-date-of-birth-year',
                          name: 'bday-year',
                          autoComplete: 'bday-year',
                        }}
                        validate={composeValidators(
                          Validator.required,
                          Validator.dateIsInFlashpackerRange,
                        )}
                      />
                      <FormDropdown
                        name="nationality"
                        validateFields={[]}
                        options={prioritizedCountryOptions}
                        validate={Validator.required}
                        TextInputProps={{
                          size: 'large',
                          label: 'Nationality',
                          placeholder: 'Select...',
                          fullWidth: true,
                          testid: 'create-account-nationality',
                          inputProps: {
                            autoComplete: 'nope', // 'country',
                          },
                        }}
                      />
                    </Stack>

                    <Typography variant="H5">Account Information</Typography>
                    <Box mb={2} />
                    <Stack gap={3} marginBottom={3.5}>
                      <Field
                        name="phoneNumber"
                        validateFields={[]}
                        validate={composeValidators(
                          Validator.required,
                          Validator.validPhoneNumber,
                        )}
                      >
                        {(props) => (
                          <FormPhoneInput
                            {...props}
                            defaultCallingCode={defaultCallingCode}
                            data-testid="create-account-phone-number"
                            label="Phone Number"
                            numberInputProps={{
                              name: 'phoneNumber',
                              placeholder: 'Enter phone number...',
                            }}
                          />
                        )}
                      </Field>
                      <FormTextInput
                        name="email"
                        validateFields={[]}
                        textInputProps={{
                          disabled: userLoggedIn,
                          id: 'user-email',
                          placeholder: 'Enter email address...',
                          label: 'Email Address',
                          testid: 'create-account-email',
                          size: 'large',
                          autoComplete: 'email',
                        }}
                        validate={validateEmail}
                      />
                      <FormTextInput
                        name="password"
                        validateFields={[]}
                        textInputProps={{
                          placeholder: 'Enter new password...',
                          label: 'Create Password',
                          testid: 'create-account-password',
                          type: 'password',
                          size: 'large',
                          autoComplete: 'new-password',
                        }}
                        validate={composeValidators(
                          Validator.required,
                          Validator.validPassword,
                        )}
                      />

                      <FormTextInput
                        name="confirmPassword"
                        validateFields={[]}
                        textInputProps={{
                          placeholder: 'Enter new password...',
                          label: 'Confirm Password',
                          testid: 'create-account-confirm-password',
                          type: 'password',
                          size: 'large',
                          autoComplete: 'new-password',
                        }}
                        validate={composeValidators(Validator.required, (value) => {
                          if (value !== values.password) {
                            return 'Passwords do not match';
                          }
                          return undefined;
                        })}
                      />
                    </Stack>
                    <Typography variant="H5" paddingBottom={2}>
                      How would you like us to keep in touch?
                    </Typography>
                    <Typography variant="Body S" paddingBottom={2}>
                      Flash Pack would love to get in touch from time to time with
                      exclusive offers and solo travel inspiration. If you’re happy for us
                      to do so, please tick your preferred form of contact below.
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <FormControlLabel
                        checked={values.contactPhone}
                        label="Phone"
                        data-testid="create-account-contact-phone"
                        control={
                          <Field<boolean>
                            name="contactPhone"
                            label="Email"
                            type="checkbox"
                            defaultValue={false}
                            validateFields={[]}
                            component={CheckboxFormField}
                          />
                        }
                      />
                      <FormControlLabel
                        checked={values.contactSms}
                        label="Text"
                        data-testid="create-account-contact-text"
                        control={
                          <Field<boolean>
                            name="contactSms"
                            label="Email"
                            type="checkbox"
                            defaultValue={false}
                            validateFields={[]}
                            component={CheckboxFormField}
                          />
                        }
                      />
                      <FormControlLabel
                        checked={values.contactEmail}
                        label="Email"
                        data-testid="create-account-contact-email"
                        control={
                          <Field<boolean>
                            name="contactEmail"
                            label="Email"
                            type="checkbox"
                            defaultValue={false}
                            validateFields={[]}
                            component={CheckboxFormField}
                          />
                        }
                      />
                    </Box>
                    <Divider />
                    <HowDidYouHearAboutUs isOther={values.referrer === 'Other'} />
                    {error && <GenericError error={error} />}
                    <LoadingButton
                      type="submit"
                      data-testid="create-account-submit"
                      variant="contained"
                      sx={{ marginTop: 4, alignSelf: 'flex-start' }}
                      loading={submitting}
                    >
                      {isPartOfBookingFlow
                        ? userLoggedIn
                          ? 'Continue'
                          : 'Create account and continue'
                        : 'Create Account'}
                    </LoadingButton>
                    <FormSpy subscription={{ values: true }}>
                      {({ form, values }) => {
                        if (values.referrer !== 'Other' && values.referrerOther) {
                          form.change('referrerOther', undefined);
                        }
                        return null;
                      }}
                    </FormSpy>
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        // if nationality is set and phone number is not set,
                        // set the default calling code based on nationality
                        if (values.nationality && !values.phoneNumber) {
                          const callingCode = getCallingCodeForCountry(
                            values.nationality as string,
                          );
                          if (!callingCode) {
                            return;
                          }
                          setDefaultCallingCode(callingCode);
                        }
                        return null;
                      }}
                    </FormSpy>
                  </form>
                );
              }}
            />
          </Stack>
        }
        additionalSection={
          <>
            {isPartOfBookingFlow ? (
              <OrderDetails
                loading={loading}
                orderDetails={data?.orderDetails}
                isFlashPerk={Boolean(data?.orderDetails.promotionalDiscount)}
              />
            ) : (
              <ImageSplash />
            )}
          </>
        }
      />
    </Layout>
  );
};

const getInitialValues = (initialValues?: Partial<CreateAccountFormData>) => {
  if (environmentIs(Environment.Development) && !window.Cypress) {
    return {
      firstName: 'John',
      middleName: 'Middle',
      lastName: 'Doe',
      gender: 'M',
      dateOfBirth: '1990-06-13',
      nationality: 'GB',
      phoneNumber: '+44 07435 213456',
      password: 'Test1234!',
      confirmPassword: 'Test1234!',
      email: `john.doe+${Math.random() * 100}@example.com`,
      referrer: 'News article',
      contactPhone: false,
      contactSms: false,
      contactEmail: false,
      ...(initialValues ?? {}),
    };
  }

  return initialValues;
};
