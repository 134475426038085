import { AuthenticationContext, useFirebaseAuth } from '@src/auth/useAuthentication';
import { FC, ReactNode, useEffect } from 'react';
import * as FirebaseAuth from 'firebase/auth';
import { useMutation } from '@apollo/client';
import { CreateSessionDocument } from '@flashpack/graphql';

export const AuthenticationProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [createSession] = useMutation(CreateSessionDocument);

  const { authenticated, loadingAuthentication, loginFromSessionIfPresent } =
    useFirebaseAuth();

  useEffect(() => {
    const auth = FirebaseAuth.getAuth();
    const unsubscribe = FirebaseAuth.onAuthStateChanged(auth, () => {
      if (auth.currentUser) {
        void createSession();
      }
    });

    return () => unsubscribe();
  }, [createSession]);

  useEffect(() => {
    if (!authenticated && !loadingAuthentication) {
      void loginFromSessionIfPresent();
    }
  }, [authenticated, loadingAuthentication, loginFromSessionIfPresent]);

  return (
    <AuthenticationContext.Provider value={{ authenticated, loadingAuthentication }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
